var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseSteps',{staticClass:"has-content-centered has-gaps"},[_c('li',{staticClass:"steps-segment",class:{
      'is-active': _vm.step === 1,
      'is-dashed': _vm.isCustomer
        ? _vm.step === 1
        : false,
    }},[_c('p',{staticClass:"steps-marker",class:{
        'is-dark': _vm.STALE,
        'is-danger': _vm.CANCELLED || _vm.REJECTED,
        'is-hollow': _vm.isCustomer ? false : _vm.step === 1,
      },domProps:{"textContent":_vm._s(1)}}),_c('div',{staticClass:"steps-content"},[_c('p',{class:{'font-semibold': _vm.step === 1},domProps:{"textContent":_vm._s(_vm.$t(_vm.step === 1 ? ("common.status." + _vm.status) : 'common.status.requested'))}})])]),_c('li',{staticClass:"steps-segment",class:{
      'is-active': _vm.step === 2,
      'is-dashed': _vm.isCustomer
        ? (_vm.step === 1 || _vm.step === 2)
        : false,
    }},[_c('p',{staticClass:"steps-marker",class:{
        'is-danger': _vm.PENDING,
        'is-dark': _vm.IDLE,
        'is-hollow': _vm.isCustomer ? _vm.PENDING : (_vm.ACTIVE || _vm.IDLE),
      },domProps:{"textContent":_vm._s(2)}}),_c('div',{staticClass:"steps-content"},[_c('p',{class:{'font-semibold': _vm.step === 2},domProps:{"textContent":_vm._s(_vm.$t(_vm.step === 2 ? ("common.status." + _vm.status) : 'common.status.active'))}})])]),_c('li',{staticClass:"steps-segment",class:{
      'is-active': _vm.step === 3,
      'is-dashed': _vm.isCustomer
        ? (_vm.step === 1 || _vm.step === 2)
        : (_vm.step < 4),
    }},[_c('p',{staticClass:"steps-marker",class:{
        'is-hollow': _vm.isCustomer ? _vm.COMPLETE : false,
      },domProps:{"textContent":_vm._s(3)}}),_c('div',{staticClass:"steps-content"},[_c('p',{class:{'font-semibold': _vm.step === 3},domProps:{"textContent":_vm._s(_vm.$t('common.status.complete'))}})])]),_c('li',{staticClass:"steps-segment",class:{
      'is-active': _vm.step === 4,
    }},[_c('p',{staticClass:"steps-marker",class:{
        'is-primary': _vm.ARCHIVED,
      },domProps:{"textContent":_vm._s(4)}}),_c('div',{staticClass:"steps-content"},[_c('p',{class:{'font-semibold': _vm.step === 4},domProps:{"textContent":_vm._s(_vm.$t(_vm.step === 4 ? ("common.status." + _vm.status) : 'common.status.finished'))}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }