










































import Vue from 'vue';
import { ApiResource } from '@/types';

export default Vue.extend({
  props: {
    commission: {
      required: true,
      type: Object as () => ApiResource.Commission,
      validator(obj: any): obj is ApiResource.Commission {
        return obj.object === 'commission';
      },
    },
  },
  data() {
    return {
      error: null,
      loading: false,
    };
  },
  methods: {
    async approve() {
      this.loading = true;

      await this.$store.dispatch('commission/Approve', {
        COMMISSION_ID: this.commission.id,
      }).catch((e) => { this.error = e; });

      this.loading = false;

      this.$emit('approved', true);

      this.$a.goal(this.$a.goals.CommissionFinished);
    },
    async disapprove() {
      this.loading = true;

      await this.$store.dispatch('commission/Disapprove', {
        COMMISSION_ID: this.commission.id,
      }).catch((e) => { this.error = e; });

      this.loading = false;

      this.$emit('disapproved', true);
    },
  },
});
