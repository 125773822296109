var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseColumnRow',[_c('BaseColumn',{staticClass:"is-three-fifths"},[_c('h3',{staticClass:"text-xl",domProps:{"textContent":_vm._s(_vm.$t('bytes.title'))}}),_c('p',{staticClass:"text-sm mb-1"},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('bytes.used', {
          value: _vm.storageBytesUsedAsString, max: _vm.storageBytesLimitAsString
        }))}}),_vm._v(" • "),_c('span',{domProps:{"textContent":_vm._s(_vm.$t('bytes.remaining', {
          percent: (100 - _vm.storageBytesUsedPercent).toPrecision(3)
        }))}}),(_vm.storageZone)?[_vm._v(" • "),_c('i18n',{attrs:{"path":"storage-zone"},scopedSlots:_vm._u([{key:"zone",fn:function(){return [_c('span',{domProps:{"textContent":_vm._s(_vm.$t(("zone." + _vm.storageZone)))}})]},proxy:true}],null,false,3682858627)})]:_vm._e()],2),_c('BaseProgress',{staticClass:"is-large",class:{
        'is-link': _vm.storageBytesUsedPercent <= 90.0,
        'is-danger': _vm.storageBytesUsedPercent > 90.0,
      },attrs:{"value":_vm.storageBytesUsed,"max":_vm.storageBytesLimit}})],1),_c('BaseColumn',[_c('h3',{staticClass:"text-xl",domProps:{"textContent":_vm._s(_vm.$t('files.title'))}}),_c('p',{staticClass:"text-sm mb-1"},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('files.used', {value: _vm.storageFilesUsed, max: _vm.storageFilesLimit}))}}),_vm._v(" • "),_c('span',{domProps:{"textContent":_vm._s(_vm.$t('files.remaining', {
          percent: (100 - _vm.storageFilesUsedPercent).toPrecision(3)
        }))}})]),_c('BaseProgress',{staticClass:"is-large",class:{
        'is-link': _vm.storageFilesUsedPercent <= 90.0,
        'is-danger': _vm.storageFilesUsedPercent > 90.0,
      },attrs:{"value":_vm.storageFilesUsed,"max":_vm.storageFilesLimit}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }