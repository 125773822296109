
























import Vue from 'vue';

export default Vue.extend({
  props: {
    commissionId: {
      required: true,
      type: String,
    },
    businessId: {
      required: false,
      type: String,
      default: null,
    },
  },
  data() {
    return {
      error: null,
      loading: false,
    };
  },
  methods: {
    async touch() {
      this.loading = true;

      const action = this.businessId ? 'business/commission/Touch' : 'commission/Touch';

      await this.$store.dispatch(action, {
        COMMISSION_ID: this.commissionId,
        BUSINESS_ID: this.businessId,
      }).catch((e) => { this.error = e; });

      this.loading = false;

      this.$emit('touched', true);
    },
  },
});
