


































































































import Vue from 'vue';

export default Vue.extend({
  props: {
    isCustomer: Boolean,
    status: {
      required: true,
      type: String,
      validator(str) {
        return [
          'waiting',

          'requested',

          'active',
          'pending',
          'complete',

          'stale',
          'idle',

          'finished',
          'archived',

          'cancelled',
          'rejected',
          'annulled',
          'dropped',
          'stopped',
          'suspended',
          'removed',
        ].includes(str);
      },
    },
  },
  computed: {
    WAITING(): boolean {
      return this.status === 'waiting';
    },
    REQUESTED(): boolean {
      return this.status === 'requested';
    },
    ACTIVE(): boolean {
      return this.status === 'active';
    },
    PENDING(): boolean {
      return this.status === 'pending';
    },
    COMPLETE(): boolean {
      return this.status === 'complete';
    },
    STALE(): boolean {
      return this.status === 'stale';
    },
    IDLE(): boolean {
      return this.status === 'idle';
    },
    FINISHED(): boolean {
      return this.status === 'finished';
    },
    ARCHIVED(): boolean {
      return this.status === 'archived';
    },
    CANCELLED(): boolean {
      return this.status === 'cancelled';
    },
    REJECTED(): boolean {
      return this.status === 'rejected';
    },
    ANNULLED(): boolean {
      return this.status === 'requested';
    },
    DROPPED(): boolean {
      return this.status === 'dropped';
    },
    STOPPED(): boolean {
      return this.status === 'stopped';
    },
    SUSPENDED(): boolean {
      return this.status === 'suspended';
    },
    REMOVED(): boolean {
      return this.status === 'removed';
    },
    step(): number {
      let step = 0;

      switch (this.status) {
        case 'archived':
        case 'finished':
          step = 4;
          break;

        case 'complete':
          step = 3;
          break;

        case 'active':
        case 'pending':
        case 'idle':
          step = 2;
          break;

        case 'requested':
        case 'waiting':
        case 'cancelled':
        case 'rejected':
          step = 1;
          break;

        default:
          step = 0;
          break;
      }

      return step;
    },
  },
});
