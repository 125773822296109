



























import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';
import CommissionProgressSteps from '@/components/commission/ProgressSteps.vue';
import FileUsage from '@/components/commission/FileUsage.vue';

interface VuexBindings {
  commissionFinder: (COMMISSION_ID: string) => ApiResource.Commission | undefined,
  findProfile: (profileId: string) => ApiResource.Profile | undefined,
}

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  components: {
    CommissionProgressSteps,
    FileUsage,
  },
  computed: {
    ...mapGetters({
      commissionFinder: 'commission/Find',
      findCommissionStats: 'commission/Stats',
      findProfile: 'profiles/Find',
    }),
    COMMISSION_ID(): string {
      return this.$route.params.COMMISSION_ID;
    },
    commission(): ApiResource.Commission | undefined {
      return this.commissionFinder(this.COMMISSION_ID);
    },
    profile(): ApiResource.Profile | undefined {
      if (!this.commission) return undefined;
      return this.findProfile(this.commission.business_id);
    },
    stats(): ApiResource.CommissionStats | undefined {
      return this.findCommissionStats(this.COMMISSION_ID);
    },
  },
  meta() {
    return {
      title: this.$t('title').toString(),
    };
  },
});
