

























import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import { MetaInfo } from 'vue-meta';
import { ApiResource } from '@/types';
import ApproveForm from '@/components/commission/ApproveForm.vue';
import TouchForm from '@/components/commission/TouchForm.vue';
import BusinessReviewForm from '@/components/commission/BusinessReviewForm.vue';
// import TheMenu from './Menu.vue';

interface VuexBindings {
  findCommission: (COMMISSION_ID: string) => ApiResource.Commission | undefined,
  findCommissionStats: (COMMISSION_ID: string) => ApiResource.CommissionStats | undefined,
  findProfile: (profileId: string) => ApiResource.Profile | undefined,
}

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  components: {
    ApproveForm,
    BusinessReviewForm,
    // TheMenu,
    TouchForm,
  },
  data() {
    return {
      error: null,
      statsError: null,
    };
  },
  computed: {
    ...mapGetters({
      findCommission: 'commission/Find',
      findCommissionStats: 'commission/Stats',
      findProfile: 'profiles/Find',
      findReview: 'commission/FindReview',
      listEvents: 'commission/event/List',
    }),
    COMMISSION_ID(): string {
      return this.$route.params.COMMISSION_ID;
    },
    commission(): ApiResource.Commission | undefined {
      return this.findCommission(this.COMMISSION_ID);
    },
    profile(): ApiResource.Profile | undefined {
      if (!this.commission) return undefined;
      return this.findProfile(this.commission.business_id);
    },
    stats(): ApiResource.CommissionStats | undefined {
      return this.findCommissionStats(this.COMMISSION_ID);
    },
    review(): ApiResource.BusinessReview | undefined {
      if (!this.commission) return undefined;
      return this.findReview(this.commission.business_id);
    },
    updatedWithin14Days(): boolean {
      if (!this.commission) return false;
      const updatedAt = new Date(this.commission.updated_at * 1000);

      updatedAt.setUTCDate(updatedAt.getUTCDate() + 14);

      return updatedAt >= new Date();
    },
  },
  watch: {
    '$route.params.COMMISSION_ID': {
      immediate: true,
      handler(COMMISSION_ID) {
        this.fetchCommission(COMMISSION_ID);
        this.fetchCommissionFiles(COMMISSION_ID);
        this.fetchCommissionItems(COMMISSION_ID);
        this.fetchCommissionMessages(COMMISSION_ID);
        this.fetchCommissionStats(COMMISSION_ID);
        this.fetchCommissionEvents(COMMISSION_ID);
      },
    },
    'commission.business_id': {
      immediate: true,
      handler(to, from) {
        // console.debug('[Commission Business]', to, from);
        if (to && (to !== from)) this.fetchBusiness(to);
      },
    },
    'commission.status': {
      immediate: true,
      handler(status): void {
        if (status === 'finished') this.fetchReview();
      },
    },
  },
  methods: {
    async fetchCommission(COMMISSION_ID: string) {
      await this.$store.dispatch('commission/Find', { COMMISSION_ID })
        .catch((e) => {
          this.error = e;
          if (e.response?.status === 404) this.$router.push({ name: 'account.commissions' });
        });
    },
    async fetchCommissionFiles(COMMISSION_ID: string) {
      await this.$store.dispatch('commission/file/List', {
        COMMISSION_ID,
        query: {
          order: 'created_at',
          direction: 'desc',
          limit: 20,
        },
      }).catch((e) => { this.error = e; });
    },
    async fetchCommissionItems(COMMISSION_ID: string) {
      await this.$store.dispatch('commission/item/List', {
        COMMISSION_ID,
        query: {
          order: 'created_at',
          direction: 'desc',
          limit: 20,
        },
      }).catch((e) => { this.error = e; });
    },
    async fetchCommissionMessages(COMMISSION_ID: string) {
      await this.$store.dispatch('commission/message/List', {
        COMMISSION_ID,
        query: {
          order: 'id',
          direction: 'desc',
          limit: 20,
        },
      }).catch((e) => { this.error = e; });
    },
    async fetchCommissionStats(COMMISSION_ID: string) {
      await this.$store.dispatch('commission/Stats', { COMMISSION_ID })
        .catch((e) => { this.statsError = e; });
    },
    async fetchBusiness(PROFILE_ID: string) {
      await this.$store.dispatch('profiles/Find', { PROFILE_ID });
    },
    async fetchCommissionEvents(COMMISSION_ID: string, limit = 100, offset = 0) {
      await this.$store.dispatch('commission/event/List', {
        COMMISSION_ID,
        query: {
          limit,
          offset,
        },
      });

      if (this.listEvents(COMMISSION_ID).length === limit + offset) {
        this.fetchCommissionEvents(COMMISSION_ID, limit, limit + offset);
      }
    },
    async fetchReview() {
      await this.$store.dispatch('commission/GetReview', {
        COMMISSION_ID: this.COMMISSION_ID,
      });
    },
  },
  meta(): MetaInfo {
    const id = (this.COMMISSION_ID || '').toUpperCase();
    return {
      titleTemplate: (chunk: string) => (chunk ? this.$t('titleTemplate', { chunk, id }) : this.$t('title', { id })).toString(),
    };
  },
});
