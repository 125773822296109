

































































import Vue from 'vue';
import { ApiResource, Zone } from '@/types';
import { bytesToString } from '@/util/bytes';

export default Vue.extend({
  props: {
    commissionStats: {
      type: Object as () => ApiResource.CommissionStats,
      required: false,
      default: null,
    },
    storageZone: {
      type: String as () => Zone,
      required: false,
      default: null,
    },
  },
  computed: {
    stats(): ApiResource.CommissionStats | null {
      return this.commissionStats;
    },
    storageBytesLimit(): number {
      if (!this.stats) return 200000000;
      return this.stats.files.size.limit_total;
    },
    storageBytesUsed(): number {
      if (!this.stats) return 0;
      return this.stats.files.size.total;
    },
    storageBytesUsedPercent(): string {
      return ((this.storageBytesUsed / this.storageBytesLimit) * 100).toPrecision(3);
    },
    storageFilesLimit(): number {
      if (!this.stats) return 20;
      return this.stats.files.count.limit_total;
    },
    storageFilesUsed(): number {
      if (!this.stats) return 0;
      return this.stats.files.count.total;
    },
    storageFilesUsedPercent(): string {
      return ((this.storageFilesUsed / this.storageFilesLimit) * 100).toPrecision(3);
    },
    storageBytesLimitAsString(): string {
      return bytesToString(this.storageBytesLimit);
    },
    storageBytesUsedAsString(): string {
      return bytesToString(this.storageBytesUsed);
    },
  },
});
